import React from "react";
import {FaSearch } from 'react-icons/fa'; 

import { Container, Row, Col } from "common/src/reusecore/Layout";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import ParticleComponent from "common/src/reusecore/Particle";

import { FaPlay, FaDownload } from "react-icons/fa";

import BannerSectionWrapper from "./banner.style";
import imgHero1 from "common/src/assets/images/hosting/banner/01.png";
import imgHero2 from "common/src/assets/images/hosting/banner/02.svg";
import imgHero3 from "common/src/assets/images/hosting/banner/03.svg";
import Fone1 from "common/src/assets/images/hosting/banner/background.svg";

const BannerHosting= () => {
  return (
    <BannerSectionWrapper id="main">
      
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col lg={6}>
            <SectionTitle
              className="section-title"
              UniWidth="100%"
            >
              <h4><div>Provide</div> <span>1k</span> <div>outgoing messages for free.</div></h4>
              <h1>
                <span className="dialong-title">Dialong</span> — omnichannel messaging platform.
              </h1>
              <p>
                Join with us by one click  <br/>
                and start introducing messengers to your business
              </p>
            </SectionTitle>
            <div className="domain-search-block">
              <div className="search-box">
                <AnchorLink className="src-btn" to="/#contact">
                  <span>Get started</span>
                </AnchorLink>
              </div>
            </div>
          </Col>
          <Col lg={6} className="icon-container">
            <img src={Fone1} alt="imgFone" className="fone-line" />
            <div className="hero-one-img-block">
              <img src={imgHero3} alt="img" />
            </div>
            <div className="hero-img-block">
              <img src={imgHero2} alt="img" />
            </div>
          </Col>
        </Row>
      </Container>
    </BannerSectionWrapper>
  );
};

export default BannerHosting;
