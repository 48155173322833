import styled from 'styled-components'


const CTASectionWrapper = styled.div`
    padding: 117px 0 0px 0;
    position: relative;
    .CTA-content{
        width: 560px;
        .btn{
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            min-width: auto;
            line-height: 30px;
            width: 159px;
            height: 48px;
            background: #00C0CC;
            border-radius: 53px;
        }
        h2{
            margin-bottom: 18px;
            font-family: 'Hammersmith One';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 56px;
            letter-spacing: -0.01em;
            color: #003033;
        }
        p{
            width: 100%;
            margin-bottom: 56px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #003033;
            opacity: 0.7;
        }
        .btn:hover{
            background: linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%), #00C0CC;

            color: #00646A;
        }
    }
    .CTA-img-container {
        text-align: -webkit-right;
        position: relative;
        .robot-img{
            position: absolute;
            left: 30%;
            top: 2%;
            z-index: 10;
        }
        .line-img{
            position: absolute;
            z-index: 1;
            left: 10%;
            top: -34%;
        }
    }
    
    .CTA-img {
        text-align: -webkit-right;
    }
  
    @media only screen and (max-width: 1350px) {
        .line-img{
            display: none;
        }
        .CTA-img-container {
            text-align: -webkit-right;
        }
    }
    @media only screen and (max-width: 1200px) {
        .CTA-img{
            max-width: 75%;
        }
        .robot-img{
            max-width: 75%;
            left: 33%;
            top: 4%;
        }
        .CTA-img-container {
            text-align: -webkit-right;
        }
    }
    @media only screen and (max-width: 1180px) {
        .robot-img{
            max-width: 80%;
            left: 33%;
            top: 4%;
        }
        .CTA-content{
            h2{
                font-size: 35px;
                line-height: 45px;
            }
        }
        p{
            width: 100%!important;
            margin-bottom: 20px!important;
        }
        .CTA-img-container {
            text-align: -webkit-right;
        }
        .line-img{
            display:none;
        }
    }
    @media only screen and (max-width: 990px) {
        padding: 50px 0 20px 0;
        .robot-img{
            max-width: 50%;
            left: 35%;
            top: 4%;
        }
        .col-one-discription{
            text-align: -webkit-center;
        }
        .CTA-img-container{
            margin-top: 80px;
        }
        .CTA-img{
            max-width: 47%;
        }
        .line-img{
            display: none;
        }
        p{
            width: 100%!important;
            margin-bottom: 20px!important;
        }
        .CTA-content{
            h2{
                font-size: 28px;
                line-height: 38px;
            }
        }
        .CTA-img-container {
            text-align: -webkit-center;
        }
    }
    @media only screen and (max-width: 760px) {
        .CTA-img{
            max-width: 45%;
        }
        .robot-img{
            max-width: 45%;
            left: 33%;
        }
    }
    @media only screen and (max-width: 568px) {
        .CTA-img{
            max-width: 45%;
        }
        .robot-img{
            max-width: 45%;
            left: 33%!important;
        }
        p{
            width: 100%!important;
            margin-bottom: 20px!important;
        }
        .CTA-img{
            max-width: 45%;
        }
        .CTA-content{
            max-width:100%;
           text-align: center;
           margin-bottom: 70px;
           margin-bottom: 0px;
        }
        .CTA-img-container {
            text-align: -webkit-center;
        }
        .btn{
            margin:auto;
        }
    }
    
`

export default CTASectionWrapper;