import React, {useState} from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import Image1 from "common/src/assets/images/hosting/img1.svg";
import Fone2 from "common/src/assets/images/hosting/1.svg";
import Line3 from "common/src/assets/images/hosting/3.svg";

import ContactWrapper from "./contact.style";

import TelegramGroup from "../../../../../integrations/TelegramGroup";

const Contact = () => {

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const clearFields = () => {
    setName("");
    setPhone("");
    setEmail("");
    setMessage("");
  }

  const onSubmit = () => {
    var group = new TelegramGroup();
    group.sendLead({
      name : name,
      phone : phone,
      email : email,
      message : message
    })
    .then(() => {
      clearFields();
      alert("Your message was successfully sent. We will contact you soon.");
    })
    .catch(() => {
      alert("Message was not sent due to an Error. Please email us on info@it-forge.org.")
    });
  };

  return (
    <ContactWrapper id="contact">
      <Container>
        <Row className="row-container">
        <Col xl={6} xs={6} sm={6} lg={6} className="form-container">
          <SectionTitle className="section-title" UniWidth="55%">
              <span>Drope your details here</span>
          </SectionTitle>
          <form onSubmit={(e) => { e.preventDefault(); onSubmit(e);  }}>
            <Container>
              <Row>
                <Col lg={12}>
                  <p className="input-name">Name*</p>
                  <input 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    type="text" 
                    required 
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                <p className="input-name">Email*</p>
                  <input 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    type="email" 
                    // placeholder="*Email" 
                    required 
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p className="input-name">Phone Number</p>
                  <input 
                    value={phone} 
                    onChange={(e) => setPhone(e.target.value)} 
                    type="phone" 
                    // placeholder="Phone Number" 
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <p className="input-name">Message</p>
                  <textarea 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)} 
                    // placeholder="Message"
                  >
                  </textarea>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Button type="submit">Send</Button>
                </Col>
              </Row>
          </Container>
        </form>
        </Col>
        <Col xl={6} xs={6} sm={6} lg={6} className="right-col-container">
          <div className="faq-thumb">
            <img src={Fone2} alt="appion faq" className="faq-fone" />
            <img src={Image1} alt="appion faq" className="faq-img"/>
            <img src={Line3} alt="appion faq" className="line-img"/>
          </div>
        </Col>
        </Row>
      </Container>
    </ContactWrapper>
  );
};

export default Contact;
