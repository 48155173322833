import styled from 'styled-components';
import FooterBG from 'common/src/assets/images/app/footer/footer-bg.png';

const FotterWrapper = styled.section`
  height: auto;
  background: #181818;
  padding-top: 50px;
  position: relative;
  .section__particle{
      position: absolute;
      &.one{
          left: 0;
          top: 20%;
          width: 240px;
      }
  }
  .footer-top-block {
    .svg-icon-right{
      left: 6.3px!important;
    }
    .svg-icon-right-location{
      left: 9.5px!important;
    }
    .svg-icon{
      position: absolute;
      left: 3.7%;
      transition: all 0.3s ease-in;
      -webkit-transition: 0.3s ease;
      path{
        fill:#01BBC7;
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
      }
    }
    .svg-fone{
      transition: all 0.3s ease-in;
      -webkit-transition: 0.3s ease;
    }
    .svg-fone path{
        fill: rgba(197, 242, 245, 0.3);
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
    }
    li:hover{
      .svg-icon path{
        fill: #FFFFFF;
      }
      .svg-fone{
        transition: all 0.3s ease-in;
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg) scale(1.1);
      }
      .svg-fone path{
          fill: #00C0CC;
          transition: 0.2s linear;
      }
    }
  }
  .footer-bottom-block{
    padding-bottom: 25px;
  }
  ul{
    padding: 0;
    list-style: none;
    margin-top: 10px!important;
    margin: 0;
    svg{
      margin-top: -9px;
    }
    li .anchor {
      justify-content: normal;
      padding-bottom: 8px;
      text-decoration-line: blink;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #CFD1D1;
      margin-top: 2px;
      padding-bottom: 12px;
    }
    li {
      padding-bottom: 10px;
      justify-content: normal;
      margin-top: 2px;
    }
    .adress-li{
      padding-top: 2px;
      margin-top: -3px!important;
      p{
        margin-top: 3px;
      }
    }
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #CFD1D1;
  }
  .anchor{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #CFD1D1;
  }
  .copyright-text{
    color: #CFD1D1;
    .anchor:hover{
      color: #f2f4f4;
      font-weight: 400;
    }
  }

  .footer-logo {  
    img{
      width: 120px;
    }
  }
  .logo-column{
    padding-left: 3.3%;
    padding-top: 26px;
    p{
      display: initial;
      font-family: 'Hammersmith One';
      line-height: 27px;
      color: #32C1FE;
      font-family: 'Hammersmith One';
      font-style: normal;
      font-weight: 400;
      font-size: 25.0129px;
      line-height: 31px;
      letter-spacing: -0.01em;
      color: #32C1FE;
    }
    a{
      margin-left: -20px;
    }
  }
  .footer-widgets {
    position: relative;
    &.first {
      padding-left: 3px;
    }
  }
  .second {
    // padding-top: 16px;
  }
  .footer-widgets {
    .widget-title {
      color: ${props => props.theme.white};
      font-size: 24px;
      margin-bottom: 33px;
    }
    .info {
      display: inline-block;
      font-size: 18px;
      line-height: 38px;
      margin-bottom: 32px;
      li {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          color: ${props => props.theme.white};
        }
      }
    }
    .social{
      li {
        display: inline-block;
        .anchor {
          display: block;
          height: 60px;
          width: 60px;
          line-height: 60px;
          border-radius: 8px;
          border: 1px solid ${props => props.theme.white};
          text-align: center;
          transition: 450ms all;
          &:hover {
          }
        }
      }
      li + li {
        margin-left: 30px;
      }
    }
    .widget-catagory {
      li {
        .anchor {
          font-size: 18px;
          line-height: 22px;
        }
      }
      li + li {
        margin-top: 15px;
      }
    }
  }
  .footer-bottom{
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #CFD1D1;
      opacity: 0.5;
      order: 1;
      margin: 0;
    }
    a{
      text-decoration-line: blink;
    }
  }
  @media only screen and (max-width: 1600px) {
    .section__particle{
      &.one{
          display: none;
      }
    } 
  }
  @media only screen and (max-width: 1280px) { 
    text-align: center;
    .info{
      
    }
    .svg-fone, .svg-icon{
      display: none;
    }
    .footer-widgets {
      .social {
        li + li {
          margin-left: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 912px) {
    text-align: center;
    .mail-text{
      margin-left: 30%;
    }
    .footer-widgets{
      margin-bottom: 0px!important;
    }
    background-position: -200px; 
    .footer-widgets {
      margin-bottom: 70px;
    }

    .footer-bottom {
      display: block;
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 360px) {
    background-position: 10%;
    text-align: center;
    .footer-logo {
      
    }
    .info{
      .footer-widgets{
        margin-bottom: 0px!important;
      }
      
      }
    }
    .footer-widgets {
      .info {
        li {
          // justify-content: center;
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {

  
    .footer-bottom {
    }
  }
`;

export default FotterWrapper;
