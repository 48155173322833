import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import FeturesSectionWrapper from "./fetures.style";

const Features = () => {
  return (
    <FeturesSectionWrapper id="use">
      <Container fullWidthSM>
        <SectionTitle className="section-title" UniWidth="100%">
          <h2>
            Where I can use Dialong
          </h2>
        </SectionTitle>
        <Row>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Retail</h3>
                <p>
                  Increase sales by providing information about your products and services 
                  to your customers in messengers
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.0621 17.6667C22.0621 17.6667 22.9421 17.12 23.3954 16.2933L28.1688 7.64C28.6621 6.76 28.0221 5.66667 27.0088 5.66667H7.27544L6.02211 3H1.66211V5.66667H4.32878L9.12878 15.7867L7.32878 19.04C6.35544 20.8267 7.63544 23 9.66211 23H25.6621V20.3333H9.66211L11.1288 17.6667H21.0621ZM8.54211 8.33333H24.7421L21.0621 15H11.7021L8.54211 8.33333ZM9.66211 24.3333C8.19544 24.3333 7.00878 25.5333 7.00878 27C7.00878 28.4667 8.19544 29.6667 9.66211 29.6667C11.1288 29.6667 12.3288 28.4667 12.3288 27C12.3288 25.5333 11.1288 24.3333 9.66211 24.3333ZM22.9954 24.3333C21.5288 24.3333 20.3421 25.5333 20.3421 27C20.3421 28.4667 21.5288 29.6667 22.9954 29.6667C24.4621 29.6667 25.6621 28.4667 25.6621 27C25.6621 25.5333 24.4621 24.3333 22.9954 24.3333Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Banks</h3>
                <p>
                  Increase your client's loyality by giving them a possibility 
                  to contact your in any text communication channel
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.33301 14H6.66634V23.3333H9.33301V14ZM17.333 14H14.6663V23.3333H17.333V14ZM28.6663 26H3.33301V28.6667H28.6663V26ZM25.333 14H22.6663V23.3333H25.333V14ZM15.9997 5.01333L22.9463 8.66667H9.05301L15.9997 5.01333ZM15.9997 2L3.33301 8.66667V11.3333H28.6663V8.66667L15.9997 2Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Support</h3>
                <p>
                  More than 60% of your clients would prefer to get 
                  support right in their messengers without having to make a call
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33366 6.33335H26.667V22.3334H6.89366L5.33366 23.8934V6.33335ZM5.33366 3.66669C3.86699 3.66669 2.68033 4.86669 2.68033 6.33335L2.66699 30.3334L8.00033 25H26.667C28.1337 25 29.3337 23.8 29.3337 22.3334V6.33335C29.3337 4.86669 28.1337 3.66669 26.667 3.66669H5.33366ZM8.00033 17H18.667V19.6667H8.00033V17ZM8.00033 13H24.0003V15.6667H8.00033V13ZM8.00033 9.00002H24.0003V11.6667H8.00033V9.00002Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Transport</h3>
                <p>
                  Send text notifications to your customers. No need to use voice calls or SMS.
                  Text them in Viber or WhatsApp. It's free and easy.
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M26.6663 10.6667H22.6663V5.33334H3.99967C2.53301 5.33334 1.33301 6.53334 1.33301 8.00001V22.6667H3.99967C3.99967 24.88 5.78634 26.6667 7.99967 26.6667C10.213 26.6667 11.9997 24.88 11.9997 22.6667H19.9997C19.9997 24.88 21.7863 26.6667 23.9997 26.6667C26.213 26.6667 27.9997 24.88 27.9997 22.6667H30.6663V16L26.6663 10.6667ZM25.9997 12.6667L28.613 16H22.6663V12.6667H25.9997ZM7.99967 24C7.26634 24 6.66634 23.4 6.66634 22.6667C6.66634 21.9333 7.26634 21.3333 7.99967 21.3333C8.73301 21.3333 9.33301 21.9333 9.33301 22.6667C9.33301 23.4 8.73301 24 7.99967 24ZM10.9597 20C10.2263 19.1867 9.18634 18.6667 7.99967 18.6667C6.81301 18.6667 5.77301 19.1867 5.03967 20H3.99967V8.00001H19.9997V20H10.9597ZM23.9997 24C23.2663 24 22.6663 23.4 22.6663 22.6667C22.6663 21.9333 23.2663 21.3333 23.9997 21.3333C24.733 21.3333 25.333 21.9333 25.333 22.6667C25.333 23.4 24.733 24 23.9997 24Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Contact center</h3>
                <p>
                  Speed Up your operators by providing them a powerfull tool. 
                  All chat history will be stored in your CRM.
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 16H22.6667C22.6667 12.32 19.68 9.33333 16 9.33333V12C18.2133 12 20 13.7867 20 16ZM25.3333 16H28C28 9.37333 22.6267 4 16 4V6.66667C21.16 6.66667 25.3333 10.84 25.3333 16ZM26.6667 20.6667C25 20.6667 23.4 20.4 21.9067 19.9067C21.7733 19.8667 21.6267 19.84 21.4933 19.84C21.1467 19.84 20.8133 19.9733 20.5467 20.2267L17.6133 23.16C13.84 21.24 10.7467 18.16 8.82667 14.3733L11.76 11.4267C12.1333 11.08 12.24 10.56 12.0933 10.0933C11.6 8.6 11.3333 7 11.3333 5.33333C11.3333 4.6 10.7333 4 10 4H5.33333C4.6 4 4 4.6 4 5.33333C4 17.8533 14.1467 28 26.6667 28C27.4 28 28 27.4 28 26.6667V22C28 21.2667 27.4 20.6667 26.6667 20.6667ZM6.70667 6.66667H8.70667C8.8 7.84 9 9 9.30667 10.1067L7.70667 11.72C7.17333 10.1067 6.82667 8.42667 6.70667 6.66667ZM25.3333 25.2933C23.5733 25.1733 21.8667 24.8267 20.2667 24.28L21.8667 22.68C23 23 24.16 23.2 25.3333 23.28V25.2933Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} className="col-item">
            <div className="fetures-block">
              <div className="fetures-content-block">
                <h3>Others</h3>
                <p>
                  Dialong will be usefull if you want to reduce contact center workload or increase your customer's loyality
                </p>
              </div>
              <div className="fetures-icon-block">
                <svg width="50" height="56" viewBox="0 0 50 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25 0L49.2487 14V42L25 56L0.751289 42V14L25 0Z" fill="#C5F2F5"/>
                </svg>
              </div>
              <div className="fetures-icon-above-block">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.33301 10.6667H10.6663V5.33334H5.33301V10.6667ZM13.333 26.6667H18.6663V21.3333H13.333V26.6667ZM5.33301 26.6667H10.6663V21.3333H5.33301V26.6667ZM5.33301 18.6667H10.6663V13.3333H5.33301V18.6667ZM13.333 18.6667H18.6663V13.3333H13.333V18.6667ZM21.333 5.33334V10.6667H26.6663V5.33334H21.333ZM13.333 10.6667H18.6663V5.33334H13.333V10.6667ZM21.333 18.6667H26.6663V13.3333H21.333V18.6667ZM21.333 26.6667H26.6663V21.3333H21.333V26.6667Z" fill="#00A2AC"/>
                </svg>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </FeturesSectionWrapper>
  );
};

export default Features;
