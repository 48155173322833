import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import VintageBox from "common/src/reusecore/VintageBox";

import imgAbout from "common/src/assets/images/hosting/imgAbout.svg";
import imgAbout1 from "common/src/assets/images/hosting/imgAbout1.svg";
import particle1 from "common/src/assets/images/app/particle/05.png";
import particle2 from "common/src/assets/images/app/particle/06.png";

import AboutSectionWrapper from "./about.style";

const About = () => {
  return (
    <AboutSectionWrapper id="about">
      <Container fullWidthSM>
        <Row Vcenter={true}>
          <Col xs={12} sm={6} className="images-block">
            <img
              className="about-thumb"
              src={imgAbout}
              alt="Image"
            />
            <img
              className="about-thumb-two"
              src={imgAbout1}
              alt="Image"
            />
          </Col>
          <Col xs={12} sm={6} className="about-content-wrap">
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h2>
                <span>Starting with Dialong is easier than anything</span>
              </h2>
            </SectionTitle>
            <p className="about-text text-one">
              Use Terrasoft Creatio to manage your chats, contacts and accounts.
              Speed up your contact center using a great graphic interface inside your CRM.
              You don't need to switch between multiple pages and apps to contact your customers now.
              All chat history and user profiles are stored right at hand.
            </p>
            <p className="about-text text-two">
              Download package from Terrasoft Marketplace, ask us for free licence and start working today.
            </p>
            <div className="src-btn">
              <AnchorLink to="/#contact">
                Download Package
              </AnchorLink>
            </div>
          </Col>
        </Row>
      </Container>
    </AboutSectionWrapper>
  );
};

export default About;
