import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import Counter from "common/src/reusecore/Counter";

import CounterSectionWrapper from "./counterSection.style";

const Counters = () => {
  return (
    <CounterSectionWrapper>
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <div className="counter__item">
              <Counter end={3.8} suffix=" bln" />
              <p>people who commonly use text communication channels</p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="counter__item">
              <Counter end={143} suffix=" min" />
              <p>people spend in messengers and social networks every day</p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="counter__item">
                <Counter end={10} suffix="+" />
              <p>channels your can use to grow up your business</p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className="counter__item">
              <Counter end={75} suffix="%+" />
              <p>of your customers would prefer to contact you in messengers</p>
            </div>
          </Col>
        </Row>
      </Container>
    </CounterSectionWrapper>
  );
};

export default Counters;
