import React from "react"; 

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle  from "common/src/reusecore/SectionTitle";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon
} from "common/src/reusecore/Accordion";

import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import FaqImage from "common/src/assets/images/hosting/faq3.svg";
import FaqImage2 from "common/src/assets/images/hosting/1.svg";
import FaqImage3 from "common/src/assets/images/hosting/2.svg";

import data from "common/src/assets/data/faq";

import FaqSectionWrapper from "./faqSection.style";

const Faq = () => {
  return (
    <FaqSectionWrapper id="faq">
      <Container fullWidthSM>
        <Row Vcenter={true} className="row-block">
          <Col className="faq-thumb-block" lg={6} md={12} xl={6} sm={12}>
            <div className="faq-thumb">
              <img src={FaqImage2} alt="appion faq" className="faq-fone" />
              <img src={FaqImage} alt="appion faq" className="faq-img"/>
              <img src={FaqImage3} alt="appion faq" className="line-img"/>
            </div>
          </Col>
          <Col className="faq-content-block" lg={6} md={12} xl={6} sm={12}>
            <SectionTitle
              className="section-title"
              leftAlign={true}
              UniWidth="100%"
            >
              <h2>
                <span>Have a question? </span>
              </h2>
            </SectionTitle>
            <Accordion>
              {data.faqs.map((faq, index) => (
                <AccordionItem key={index}>
                  <AccordionTitle  className="accordion-item-ask">
                    <h5>{faq.title}</h5>
                    <IconWrapper>
                      <svg className="svg-fone-icon" width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 38L0.545518 28.5L0.545519 9.5L17 -1.66103e-06L33.4545 9.5L33.4545 28.5L17 38Z" fill="#C5F2F5"/>
                      </svg>
                      <OpenIcon className="done-icon">
                        <IoIosArrowForward />
                      </OpenIcon>
                      <CloseIcon className="close-icon">
                        <IoIosArrowDown />
                      </CloseIcon>
                    </IconWrapper>
                  </AccordionTitle>
                  <AccordionBody className={faq.blockClass}>
                    <div className={faq.class}>
                      <p className="item-answer-name">Operator</p>
                      <p>{faq.content}</p>
                      <span>12:12 AM</span>
                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.36133 9.4899L4.78804 11.696C5.01231 11.8999 5.3548 11.8999 5.57906 11.696L12.9447 5" stroke="#003033" stroke-width="1.17593" stroke-linecap="round"/>
                        <path d="M8.24219 11.4141L8.52657 11.6801C8.75258 11.8914 9.10371 11.8914 9.32972 11.6801L16.4737 5" stroke="#003033" stroke-width="1.17593" stroke-linecap="round"/>
                      </svg>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </FaqSectionWrapper>
  );
};

export default Faq;
