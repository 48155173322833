import styled from 'styled-components';

const BannerSectionWrapper = styled.div`
    padding: 175px 0 135px 0;
    position: relative;
    .fone-line{
        max-width: 250%;
        position: absolute;
        z-index: 1;
        top: -45%;
        right: -11.5%;
    }
    .section-particle{
        position: absolute;
        z-index: 1;
        &.one{
            top: -4%;
            right: 15%;
        }
    }
    .icon-container{
        text-align: -webkit-right;
    }
    .section-title{
        z-index: 99;
        position: relative;
        text-align: left;
        margin-top: 10px;
        h4{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #003033;
            margin-bottom: 35px;

            div{
                opacity: 0.7;
                display: inline-block;
            }
            span{
                width: 48px;
                height: 30px;
                background: #CCFCFF;
                border-radius: 21px;
                padding: 3px 15px;
                color: #003033;
                font-size: 20px;
                opacity: 1;
            }
        }
        .dialong-title:hover{
            background: linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%), #003033;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        h1{
            margin-bottom: 20px;
            font-family: 'Hammersmith One', sans-serif;
            font-weight: 400;
            font-size: 44px;
            line-height: 56px;
            letter-spacing: -0.01em;
            color: #003033;
        }
        p{
            margin: 0 0 55px 0;
            font-family: 'Poppins';
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #003033;
            opacity: 0.7;
        }
    }
    .domain-search-block{
        .search-box{
            position: relative;
            width: 90%;
            z-index: 999;
        }
        input{
            height: 80px;
            border:none;
            background: #fff;
            width: 100%;
            padding: 20px 30px;
            font-size: 18px;
            border-radius: 50px;
            box-shadow: rgba(206, 206, 206, 0.78) 0px 1px 23px -8px;
        }
        .src-btn:hover {
            background: linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%), #00C0CC;
            span{
                color: #00646A;
            }
        }
        .src-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            width: 155px;
            height: 48px;
            min-width: auto;
            line-height: 30px;
            border-radius: 53px;
            background: #00C0CC;
            z-index: 999999999999999999;
            
            svg{
                font-size: 30px;
                color: #fff;
            }

            a {
                color: #fff;
                font-family: 'Poppins';
            }
        }
        .domain-extention-block{
            text-align: left;
            margin-top: 40px;
            span{
                cursor: pointer;
                height: 60px;
                width: 60px;
                display: inline-block;
                border-radius: 8px;
                box-shadow:  rgba(206, 206, 206, 0.78) 0px 1px 23px -8px;
                font-size: 15px;
                text-align: center;
                line-height: 60px;
            }
            span+span{
                margin-left: 20px;
            }
            .com{
                color: #E7A8CA;
                box-shadow:  rgba(231, 168, 202, 0.78) 0px 1px 23px -8px;
            }
            .org{
                color: #A29BFE;
                box-shadow:  rgba(162, 155, 254, 0.78) 0px 1px 23px -8px;
            }
            .net{
                color: #0984E3;
                box-shadow:  rgba(9, 132, 227, 0.78) 0px 1px 23px -8px;
            }
            .dev{
                color: #AD62AA;
                box-shadow:  rgba(173, 98, 170, 0.78) 0px 1px 23px -8px;
            }
            .xyz{
                color: #413C69;
                box-shadow:  rgba(65, 60, 105, 0.78) 0px 1px 23px -8px;
            }
        }
    }
    .hero-one-img-block{
        position: absolute;
        z-index: 100;
        top: 15px;
        left: 4.5%;
        img{
            max-width: none;
        }
    }


    /**
    * ----------------------------------------
    * animation smooth-up-down
    * ----------------------------------------
    **/

    @keyframes smooth-up-down{
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        20% {
            -webkit-transform: translateY(-10px);
            transform: ttranslateY(-10px);
        }
        50% {
            -webkit-transform: translateY(-20px);
            transform: ttranslateY(-20px);
        }
        70% {
            -webkit-transform: translateY(-10px);
            transform: ttranslateY(-10px);
        }
        100% {
            -webkit-transform: translateY(0px);
            transform: ttranslateY(0px);
        }
    }

    @-moz-keyframes smooth-up-down{
        0% {
            -moz-transform: rotate(-80deg);
                    transform: rotate(-80deg);
        }
        20% {
            -moz-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        50% {
            -moz-transform: rotate(80deg);
                    transform: rotate(80deg);
        }
        70% {
            -moz-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        100% {
            
            -moz-transform: rotate(-80deg);
            transform: rotate(-80deg);
        }
    
    }

    @-webkit-keyframes smooth-up-down{
        0% {
            -webkit-transform: rotate(-80deg);
                    transform: rotate(-80deg);
        }
        20% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        50% {
            -webkit-transform: rotate(80deg);
                    transform: rotate(80deg);
        }
        70% {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        100% {
            
            -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg);
        }
    }

    @-o-keyframes smooth-up-down{

        0% {
            -o-transform: rotate(-80deg);
                    transform: rotate(-80deg);
        }
        20% {
            -o-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        50% {
            -o-transform: rotate(80deg);
                    transform: rotate(80deg);
        }
        70% {
            -o-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        100% {
            -o-transform: rotate(-80deg);
            transform: rotate(-80deg);
        }

    }

    @-ms-keyframes smooth-up-down{

        0% {
            -ms-transform: rotate(-80deg);
                    transform: rotate(-80deg);
        }
        20% {
            -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        50% {
            -ms-transform: rotate(80deg);
                    transform: rotate(80deg);
        }
        70% {
            -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
        }
        100% {
            -ms-transform: rotate(-80deg);
            transform: rotate(-80deg);
        }
    }

    @media only screen and (max-width: 1500px) {
        .fone-line{
            display: none;
        }
    }
    @media only screen and (max-width: 1200px) {
        .section-particle{
            &.one{
                right: 0%;
            }
        }
        .hero-img-block{
            max-width: 65%;
        }
        .hero-one-img-block{
            margin-left: 13%;
            margin-top: -2%;
            max-width: 100%!important;
            img{
                max-width: 115%;
                margin-left: 3%;
                margin-top: 0%;
            }
        }
    }
    @media only screen and (max-width: 992px) {
        .section-title{
            text-align: -webkit-center;
        }
        .domain-search-block{
            text-align: -webkit-center;
        }
        .icon-container{
            position: absolute;
            text-align: -webkit-center;
            opacity: 0.2;
            left: 0;
        }
        .hero-one-img-block img{
            margin-left: -4%;
        }
    }
    @media only screen and (max-width: 912px) {
        margin: 75px 0 0 0;
        padding: 50px 0 60px 0;
        .icon-container, .col{
            margin-top: 10%!important;
        }
        .hero-img-block{
            max-width: 60%!important;
            display: none;
        }
        .hero-one-img-block{
            left: 0%!important;
            margin-top: -39%;
            max-width: 80%!important;

            img{
                max-width: 100%;
            }
        }

        .section-title{
            text-align: center;
            br{
                display: none;
            }
            h1{
                font-size: 32px;
                line-height: 42px;
                margin: 0 0 25px 0;
            }
        }
        p{ 
            margin: 0 0 60px 0;
        }
        .domain-search-block{
            margin-bottom: 30px;
            .search-box{
                width: 100%;
                margin-top: 20%;
                text-align: -webkit-center;
            }
            .domain-extention-block{
                text-align: center;
            }
        }
        .hEzymV{
            text-align: -webkit-center;
            margin-top: 8px;
        }
     }
     @media only screen and (max-width: 760px) {
        padding: 50px 0 100px 0;
        .hero-one-img-block{
            margin-top: -45%;
            margin-left: 0px;
            img{
                max-width: 115%;
                margin-left: 10%;
            }
        }
     }
     @media only screen and (max-width: 568px) {
        background: none;
        .hero-one-img-block{
            margin-top: -50%;
        }
        .hero-img-block{
            // display: none;
            margin-top: -3px;
        }
        .hEzymV{
            text-align: -webkit-center;
            margin-top: 8px;
            .hero-one-img-block{
                margin-left: 0%;
                max-width: 40%;
                left: 30%;
            }
            .hero-img-block{
                max-width: 40%;
            }
        }
        .icon-container {
            position: absolute;
            opacity: 0.27;
        }
     }
     @media only screen and (max-width: 480px) {
        .hero-one-img-block{
            margin-left: 10.5%;
            margin-top: -50%;
            img{
                margin-left: 6%;
            }
        }
        padding: 20px 0 50px 0;
        .section-title{
            h1{
                font-size: 35px;
                line-height: 50px;
            }
        }
        .domain-search-block{
            .domain-extention-block{
                span{
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    font-size: 12px;
                }
            } 
            .src-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
            }
            input{
                height: 70px;
            }
        } 
     }
     @media only screen and (max-width: 380px) {   
        .hero-one-img-block{
            display: none;
        }   
        .section-title{
            h1{
                font-size: 30px;
                line-height: 46px;
                margin: 0 0 30px 0;
            }
            h4{
                font-size: 15px;
            }
        }
     }
     @media only screen and (max-width: 330px) {
        .section-title{
            h1{
                font-size: 23px;
                line-height: 35px;
            }
        }
     }
     
`

export default BannerSectionWrapper;