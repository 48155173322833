import styled from 'styled-components'


const CounterSectionWrapper = styled.section`
    padding: 115px 0 60px 0;
    z-index: 1000;
    .counter__item {
        text-align: center;
        margin-bottom: 50px;
        span{
            margin-bottom: 15px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 62.9246px;
            line-height: 94px;
            letter-spacing: -0.01em;
            color: #00A2AC;
        }
        p{
            color: #003033;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin: 0;
        }
    }
    
    @media only screen and (max-width: 912px) {
        padding-top: 100px;
        padding-bottom: 0px;
     }
     @media only screen and (max-width: 568px) {
        padding-top: 50px;
        padding-bottom: 0px;
     }
    
`

export default CounterSectionWrapper;