import React from "react";
import { Link } from "gatsby";

import { Container, Row, Col } from "common/src/reusecore/Layout";

import { FiMail, FiPhoneCall } from "react-icons/fi";
import { FaViber, FaTelegram, FaSkype } from "react-icons/fa";

import logo from "common/src/assets/images/app/footer/itforge.svg";

import FotterWrapper from "./footer.style";

const Footer = () => {
  return (
    <FotterWrapper>
      <Container>
        <Row className="footer-top-block">
          <Col xs={12} sm={6} lg={3}>
            <div className="footer-widgets first">
              <ul className="info">
                <li>
                  <svg className="svg-fone" width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0L31.5885 9V27L16 36L0.411543 27V9L16 0Z" fill="#C5F2F5"/>
                  </svg>
                  <svg className="svg-icon svg-icon-phone" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9H14C14 6.24 11.76 4 9 4V6C10.66 6 12 7.34 12 9ZM16 9H18C18 4.03 13.97 0 9 0V2C12.87 2 16 5.13 16 9ZM17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.33 11.9 13.22 11.88 13.12 11.88C12.86 11.88 12.61 11.98 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.78L5.82 5.57C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM2.03 2H3.53C3.6 2.88 3.75 3.75 3.98 4.58L2.78 5.79C2.38 4.58 2.12 3.32 2.03 2ZM16 15.97C14.68 15.88 13.4 15.62 12.2 15.21L13.4 14.01C14.25 14.25 15.12 14.4 16 14.46V15.97Z" fill="#00A2AC"/>
                  </svg>
                  <a className="anchor" href="tel:+421951810903">+421 951 810 903</a>
                </li>
                <li>
                  <svg className="svg-fone" width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0L31.5885 9V27L16 36L0.411543 27V9L16 0Z" fill="#C5F2F5"/>
                  </svg>
                  <svg className="svg-icon svg-icon-phone" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9H14C14 6.24 11.76 4 9 4V6C10.66 6 12 7.34 12 9ZM16 9H18C18 4.03 13.97 0 9 0V2C12.87 2 16 5.13 16 9ZM17 12.5C15.75 12.5 14.55 12.3 13.43 11.93C13.33 11.9 13.22 11.88 13.12 11.88C12.86 11.88 12.61 11.98 12.41 12.17L10.21 14.37C7.38 12.93 5.06 10.62 3.62 7.78L5.82 5.57C6.1 5.31 6.18 4.92 6.07 4.57C5.7 3.45 5.5 2.25 5.5 1C5.5 0.45 5.05 0 4.5 0H1C0.45 0 0 0.45 0 1C0 10.39 7.61 18 17 18C17.55 18 18 17.55 18 17V13.5C18 12.95 17.55 12.5 17 12.5ZM2.03 2H3.53C3.6 2.88 3.75 3.75 3.98 4.58L2.78 5.79C2.38 4.58 2.12 3.32 2.03 2ZM16 15.97C14.68 15.88 13.4 15.62 12.2 15.21L13.4 14.01C14.25 14.25 15.12 14.4 16 14.46V15.97Z" fill="#00A2AC"/>
                  </svg>
                  <a className="anchor" href="tel:+421951810904">+421 951 810 904</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <div className="footer-widgets second">
              <ul className="info">
                <li>
                  <svg className="svg-fone" width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0L31.5885 9V27L16 36L0.411543 27V9L16 0Z" fill="#C5F2F5"/>
                  </svg>
                  <svg className="svg-icon svg-icon-right" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" fill="#00A2AC"/>
                  </svg>
                  <p className="mail-text">
                    <a className="anchor" href="mailto:info@it-forge.sk">info@it-forge.sk</a>
                  </p>
                </li>
                <li className="adress-li">
                  <svg className="svg-fone" width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0L31.5885 9V27L16 36L0.411543 27V9L16 0Z" fill="#C5F2F5"/>
                  </svg>
                  <svg className="svg-icon-right-location svg-icon" width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM2 7C2 4.24 4.24 2 7 2C9.76 2 12 4.24 12 7C12 9.88 9.12 14.19 7 16.88C4.92 14.21 2 9.85 2 7Z" fill="#00A2AC"/>
                    <path d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5Z" fill="#00A2AC"/>
                  </svg>
                  <p>
                    82104 Bratislava-Ružinov, Doležalova 3424/15C
                  </p>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="footer-bottom-block">
          <Col xs={12} sm={6} lg={3} className="logo-column">
            <a className="footer-logo" target="_blank" href="https://it-forge.sk/">
              <img src={logo} alt="logo" />
            </a>
          </Col>
          <Col xs={12} sm={6} lg={6}>
            <div className="footer-bottom">
              <p className="copyright-text">
                Copyright ©
                <a className="anchor" href="https://it-forge.sk/" target="_blank">
                  IT Forge
                </a>
                . All Right Reserved 2022
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FotterWrapper>
  );
};

export default Footer;
