import styled from 'styled-components';

const ContactWrapper = styled.section`
    padding: 200px 0 196px 0;
    overflow: hidden;
    background: transparent;
    align-items: center;
    form{
        margin-top: 23px;
        margin-left: -9%;
        padding-left: 37px!important;
        padding-right: 37px!important;
        width: 580px;   
        padding: 51px 0px 51px 0px;
        background: #ECF8F9;
        border-radius: 32px;
        text-align: center;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        &:hover{
            background: #D9F1F2;
        }
    }
    input, textarea{
        background: #FFFFFF;
        opacity: 0.8;
        border-radius: 14px;
        display: block;
        padding: 15px;
        width: 100%;
        height: 60px;
        font-size: 16px;
        margin: 0 0 23px 0;
        box-shadow: none;
        transition: 450ms all;
        border: 0px solid #cccccc;
        font-family: inherit;
        &:hover,&:focus{
            border-color: #909090;
        }
    }
    .input-name{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #003033;
        margin-bottom: 0;
        text-align: left;
        margin-left: 5%;
    }
    textarea {
        height:150px;
        resize:none;
    }
    button{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #FFFFFF;
        width: 159px;
        height: 48px;
        background: #00C0CC;
        border-radius: 53px;
        display: block;
        transition: 450ms all;
        font-size: 16px;
        padding: 12px;
        margin: 10px auto 0px auto;
        &:hover{
            background: #FFFFFF;
            color: #00C0CC;
        }
    }
    .right-col-container{
        align-self: center;
        text-align: -webkit-right;
    }
    .faq-fone{
        max-width: none;
        margin-top: 10%;
    }
    .faq-img{
        position: absolute;
        right: 4%;
        top: 18%;   
    }
    .line-img{
        position: absolute;
        max-width: none;
        top: -365px;
        right: -85px;
        z-index: 1;

    }
    .form-container{
        z-index: 100;
    }
    .section-title{
        text-align: left;
        margin-left: 0;
        width: 100%!important;
        
        span{
            z-index: 100;
            font-family: 'Hammersmith One';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 56px;
            letter-spacing: -0.01em;
            color: #003033;
            font-family: 'Hammersmith One';
        }
    }
    @media only screen and (max-width: 1350px) {
        .line-img{
            display: none;
        }
        form{
            width: 100%;
            margin-left: 10%;
        }
        .section-title{
            margin-left: 10%;
        }
        .faq-fone{
            max-width: 100%;
            margin-top: 25%;
        }
        .faq-thumb{
            margin-left: 20%;
            .faq-img{
                top: 25%;
                right: 5%;
                max-width: 70%;
            }
        }
    }
    @media only screen and (max-width: 1180px) {
        padding: 115px 0 140px 0;
        .line-img{
            display: none;
        }
        .faq-fone{
            max-width: 100%;
            margin-top: 25%;
        }
        .faq-thumb{
            margin-left: 20%;
            .faq-img{
                top: 25%;
                right: 5%;
                max-width: 70%;
            }
        }
        .section-title{
            margin-left: 10%;
        }
        form{
            width: 100%;
            margin-left: 10%;
        }
    }
    @media only screen and (max-width: 912px) {
        padding: 0px 0 80px 0;
        .form-container{
            max-width: 100%;
        }
        .faq-thumb{
            margin-left: 0;
            margin-bottom: 30px;
            text-align: -webkit-center;
        }
        .line-img{
            display: none;
        }
        .faq-img{
            width: 50%;
            top: 30%!important;
            left: 25%;
            margin-left: 0;
        }
        .faq-fone{
            margin-left: 0;
            max-width: none;
            width: 85%!important;
            margin-top: 15%;
        }
        .section-title{
            margin-left: 0px;
            text-align: center;
        }
        h2{
            span{
                font-size: 35px!important;
            }
        }
        .row-container{
            flex-direction: column-reverse;
        }
        form{
            margin-top: 25px;
            width: 90%;
            margin-left: 5%;
            padding: 50px;
            input, textarea{
                width: 100%;
            }
            button{
                width: 50%;
            }
        }

    }
    @media only screen and (max-width: 568px) {
        form{
            padding: 30px;
            display: block;
            input, button, textarea{
                width: 100%;
                margin-bottom: 30px;
            }
        }
        .faq-fone{
            margin-top: 25%;
        }
        .faq-img{
            top: 30%!important;
        }
    }
    @media only screen and (max-width: 480px) {
        form{
            padding: 30px 15px;
            input, button, textarea{
                width: 100%;
            }
        }
        .faq-img{
            top: 31%!important;
        }
    }
    
`

export default ContactWrapper;