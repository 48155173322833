import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import SectionTitle from "common/src/reusecore/SectionTitle";
import Button from "common/src/reusecore/Button";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import particle1 from "common/src/assets/images/app/particle/14.png";
import particle2 from "common/src/assets/images/app/particle/15.png";

import data from "common/src/assets/data/pricing";

import PricingSectionWrapper from "./pricingSection.style";

const Pricing = () => {
  return (
    <PricingSectionWrapper id="pricing">
      <Container>
        <SectionTitle UniWidth="65%">
          <h2 className="section-title-name">
            <span>Choose your best plan </span>
          </h2>
        </SectionTitle>
        <Row>
          {data.pricings.map((pricing, index) => (
            <Col xs={12} sm={12} md={6} lg={4} xl={4} key={index} className="one-column-block">
              <div className="pricing-block">
                <div className="thumb-background">
                    <svg width="130" height="148" viewBox="0 0 130 148" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M65 0L129.086 37V111L65 148L0.914124 111V37L65 0Z" fill="url(#paint0_linear_425_376)"/>
                    <path d="M65 0L129.086 37V111L65 148L0.914124 111V37L65 0Z" fill="#C5F2F5"/>
                    <defs>
                    <linearGradient id="paint0_linear_425_376" x1="38.3427" y1="34.5" x2="196.32" y2="169.822" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#9EFBD3"/>
                    <stop offset="0.48" stop-color="#57E9F2"/>
                    <stop offset="1" stop-color="#45D4FB"/>
                    </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="thumb-block"> 
                  <img src={pricing.thumb} alt="appion app landing" />
                </div>
                <div className="price-block">
                  <h3>{pricing.price}</h3>
                  <p className="package-name">{pricing.name}</p>
                </div>
                <p className="package-description">{pricing.description}</p>
                <div className="details-block">
                  {pricing.services.map((service, index) => (
                    <span className={service.className} key={index}>
                      {service.className == "on" ? (
                        <IoMdCheckmark />
                      ) : (
                        <IoMdClose />
                      )}
                      {service.content}
                    </span>
                  ))}
                </div>
                <AnchorLink as="a" className="pricing-btn" to="/#contact">
                    {pricing.btnText}
                </AnchorLink>
              </div>
            </Col>
          ))}
        </Row>
        <SectionTitle UniWidth="65%">
          <h2 className="section-title-name what-section-title-name">
            <span>What about some support?</span>
          </h2>
        </SectionTitle>
        <Row>
          {data.support.map((pricing, index) => (
            <Col xs={12} md={6} sm={12} lg={4} xl={4} key={index} className="two-column-block">
              <div className="pricing-block support-block">
                <div className="price-block">
                  <h3>{pricing.price}</h3>
                  <p className="support-pricing-name">{pricing.name}</p>
                </div>
                <div className="details-block">
                  {pricing.services.map((service, index) => (
                    <span className={service.className} key={index}>
                      {service.className == "on" ? (
                        <IoMdCheckmark />
                      ) : (
                        <IoMdClose />
                      )}
                      {service.content}
                    </span>
                  ))}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </PricingSectionWrapper>
  );
};

export default Pricing;
