import React from "react";

import { Container, Row, Col } from "common/src/reusecore/Layout";

import CTASectionWrapper from "./CTASection.style";

import image1 from "common/src/assets/images/hosting/CTA/01.svg";
import image2 from "common/src/assets/images/hosting/CTA/02.svg";
import image3 from "common/src/assets/images/hosting/CTA/1.svg";

import { AnchorLink } from "gatsby-plugin-anchor-links";

const CTAHosting = () => {
  return (
    <CTASectionWrapper id="chatbot">
      <Container>
        <Row Vcenter={true}>
          <Col xs={12} sm={12} xl={6} lg={6} className="col-one-discription">
            <div className="CTA-content">
              <h2>
                Introduce ChatBots <br/> to you Business
              </h2>
              <p>
                Are you interested in some automation? We can do it! <br/>
                Our team developed a flexible NLP Engine which can be used as AI feature for a ChatBot.<br/> 
                Communicating AI ChatBots will process up to 80% of your Client Requests. <br/>
                Let us know if you are interested in it!
              </p>
              <AnchorLink className="btn" to="/#contact">
                Contact Us
              </AnchorLink>  
            </div>
          </Col>
          <Col xs={12} sm={12} xl={6} lg={6} className="CTA-img-container">
            <div className="CTA-img">
              <img src={image2} alt="img" className="fone-robot-img"/>
              <img src={image1} alt="img" className="robot-img"/>
              <img src={image3} alt="img" className="line-img"/>
            </div>
          </Col>
        </Row>
      </Container>
    </CTASectionWrapper>
  );
};

export default CTAHosting;
