import imgPricing1 from 'common/src/assets/images/app/pricing/0111.svg';
import imgPricing2 from 'common/src/assets/images/app/pricing/0222.svg';
import imgPricing3 from 'common/src/assets/images/app/pricing/0333.svg';

const data = {
    support: [
    {
        thumb: imgPricing1,
        name: 'Basic',
        price: '$950',
        description: '',
        link: '#',
        btnText: 'Get Package',
        services:[
            {
                content: '9-18 GMT +3',
                className: 'on'
            },
            {
                content: '8h reaction time.',
                className: 'on'
            },
            {
                content: '40h execution time.',
                className: 'on'
            },
            {
                content: 'Email channel.',
                className: 'on'
            },
            {
                content: 'Telegram channel.',
                className: 'off'
            },
            {
                content: 'Phone channel.',
                className: 'off'
            },
            {
                content: 'Personal assistant.',
                className: 'off'
            },
            {
                content: 'Development consultaions.',
                className: 'off'
            }
        ]
        },{
            thumb: imgPricing2,
            name: 'Standard',
            price: '$2880 / year',
            description: '',
            link: '#',
            btnText: 'Get Package',
            services:[
                {
                    content: '9-21 GMT +3',
                    className: 'on'
                },
                {
                    content: '6h reaction time.',
                    className: 'on'
                },
                {
                    content: '16h execution time.',
                    className: 'on'
                },
                {
                    content: 'Email channel.',
                    className: 'on'
                },
                {
                    content: 'Telegram channel.',
                    className: 'on'
                },
                {
                    content: 'Phone channel.',
                    className: 'off'
                },
                {
                    content: 'Personal assistant.',
                    className: 'off'
                },
                {
                    content: 'Development consultaions.',
                    className: 'off'
                }
            ]
        },
        {
        thumb: imgPricing3,
        name: 'VIP',
        price: '$5280 / year',
        description: '',
        link: '#',
        btnText: 'Get Package',
        services:[
            {
                content: '9-21 GMT +3',
                className: 'on'
            },
            {
                content: '4h reaction time.',
                className: 'on'
            },
            {
                content: '8h execution time.',
                className: 'on'
            },
            {
                content: 'Email channel.',
                className: 'on'
            },
            {
                content: 'Telegram channel.',
                className: 'on'
            },
            {
                content: 'Phone channel.',
                className: 'on'
            },
            {
                content: 'Personal manager.',
                className: 'on'
            },
            {
                content: 'Development consultaions.',
                className: 'on'
            }
        ]
        }
    ],
    pricings: [
      {
        thumb: imgPricing1,
        name: 'Basic',
        price: '$3450 / year',
        description: 'Recommended for a company with a small contact center.',
        link: '#',
        btnText: 'Get Package',
        services:[
            {
                content: '100k outoming messages.',
                className: 'on'
            },
            {
                content: 'Unlimited channels.',
                className: 'on'
            },
            {
                content: 'Unlimited operators.',
                className: 'on'
            },
            {
                content: 'On-cloud deployment.',
                className: 'on'
            },
            {
                content: 'On-site deployment.',
                className: 'off'
            }
        ]
      },{
        thumb: imgPricing2,
        name: 'Pro',
        price: '$6250 / year',
        description: 'Best choise for companies with up to 10 operators.',
        link: '#',
        btnText: 'Get Package',
        services:[
            {
                content: '250k outoming messages.',
                className: 'on'
            },
            {
                content: 'Unlimited channels.',
                className: 'on'
            },
            {
                content: 'Unlimited operators.',
                className: 'on'
            },
            {
                content: 'On-cloud deployment.',
                className: 'on'
            },
            {
                content: 'On-site deployment.',
                className: 'on'
            }
        ]
      },
      {
        thumb: imgPricing3,
        name: 'Business',
        price: 'Negotiated',
        description: 'You need to process a very huge traffic? Or you need something special like Communicating AI ChatBots? Or maybe you desire to integrate Dialong into your existing business system? Contact us and let\'s talk about it!',
        link: '#',
        btnText: 'Contact Us',
        services:[
            
        ]
      }
    ]
  };
  export default data;
  