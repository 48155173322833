import styled from 'styled-components';

const PricingSectionWrapper = styled.section`
    padding: 177px 0 110px 0;
    overflow: hidden;
    position: relative;
    z-index: 2;
    background: transparent;
    .section__particle{
        position: absolute;
        background: transparent;
        &.one{
            width: 190px;
            left: -5%;
            bottom: 0px;
        }
        &.two{
            width: 144px;
            top: 27%;
            right: -4%;
        }
    }
    .support-block{
        padding-top: 5px!important;
        height: 530px!important;
    }
    .what-section-title-name{
        margin: 80px 0 45px 0!important;
    }
    .section-title-name{
        margin: 40px 0 90px 0;
        span{
            font-family: 'Hammersmith One';
            font-style: normal;
            font-weight: 400;
            font-size: 44px;
            line-height: 55px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #003033;
        }
    }
    .pricing-block{
        width: 360px;
        height: 595px;
        background: #ECF8F9;
        border-radius: 32px;

        padding: 49px 51px 46px 51px;
        margin-bottom: 70px;
        position: relative;
        text-align: center;
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
        .package-name{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #A1B0B1;
        }
        .package-description{
            margin-bottom: 38px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #003033;
        }
        .pricing-btn:hover{
            background: linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%), #00C0CC;

            color: #00646A;
        }
        &:hover{
            background: #D9F1F2;
            transition: all 0.2s ease-in;
            border-radius: 16px;
            .pricing-btn{
                opacity: 1;

            }
            h3{
                color: #038f98;
            }

            .package-name,.support-pricing-name {
                color: #819091;
            }

            .thumb-background svg{
                transition: all 0.3s ease-in;
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg) scale(1.1);
            }
            .thumb-background svg path{
                fill: #A6EBF0;
                transition: 0.2s linear;
            }

            .thumb-block img {
                transition: 0.2s linear;
                transform: scale(1.1);
            }
        }
    }
    .thumb-block{   
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
        position: absolute;
        top: -45px;
        left: 92px;
    }
    .thumb-background svg{
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
    }
    .thumb-background svg path{
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
    }
    .thumb-background{
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
        position: absolute;
        top: -73px;
        left: 120px;
    }
    .price-block{
        text-align: center;
        padding: 0px 0 32px 0;
        h3{
            margin: 55px 0 17px 0; 
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 24px;
            text-align: center;
            color: #00A2AC;
        }
        p{
            color: #A1B0B1;
            margin-bottom: 0;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
        }
        &:hover{

        }
    }
    .price-block ~ p{
        margin-top: 0;
        margin-bottom: 22px;
        text-align: center;
    }
    .details-block{
        text-align: left;
        span{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #003033;
            display: flex;
            align-items: center;
            svg{
                fill: #00A2AC;
                margin-right: 12px;
            }
        }
        .off{
            svg{
                fill: #C73460;
            }
        }
        span+span{
            margin-top: 12px;
        }
    }
    .pricing-btn{
        align-items: center;
        padding: 12px 32px;
        background: #00C0CC;
        border-radius: 53px;
        width: 50%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 15px 20px;
        margin: 0 auto;
        position: absolute;
        text-align: center;
        bottom: -23px;
        left: 0;
        right: 0;
        opacity: 0;

    }
    @media only screen and (max-width: 1280px) {
        padding: 90px 0 210px 0;
        
        .pricing-block{
            padding: 40px 15px 35px 15px;
            img{
                max-width: 90%;
            }
        }
        .thumb-block{
            left: 22%;
        }
        .thumb-background{
            left: 30%;
        }
        .section__particle{
            &.one{
                bottom: 0px;
            }
            &.two{
                display: none;
            }
        }
    }
    @media only screen and (max-width: 1200px) {
        padding: 90px 0 100px 0;
        .one-column-block{
            margin-top: 35px;
        }
        .pricing-block{
            width: 100%;
            padding: 40px 15px 35px 15px;

            img{
                max-width: 90%;
            }
        }
    }
    @media only screen and (max-width: 912px) {
        padding: 50px 0 35px 0;
        .section__particle{
            &.one {
                bottom: -122px;
            }
        }
        .pricing-block{
            width: 100%;
        }
        .pricing-block{
            width: 100%;
            margin-bottom: 40px;
            padding: 40px 30px 35px 30px;
        }
        .thumb-block{
            top: -26px;
            left: 23%;
        }
        .section-title-name{
            margin-bottom: 40px;
        }
        .one-column-block{
            margin-top: 35px;
        }
        .thumb-background{
            left: 35%;
            top: -45px;
            svg{
                width: 100%;
                height: 120px;
            }
        }
    }
    @media only screen and (max-width: 768px) {
        .thumb-background{
            left: 45%;
            top: -10%;
        }
        .thumb-block{
            left: 38%;
            top: -7%;
        }
        .pricing-block{
            margin-top: 25px;
        }
    }
    @media only screen and (max-width: 540px) {
        padding: 50px 0 35px 0;
        .section__particle{
            &.one {
                bottom: -122px;
            }
        }
        .pricing-block{
            width: 100%;
            margin-bottom: 40px;
            padding: 40px 30px 35px 30px;
        }
        .thumb-background{
            left: 37%;
            top: -10%;
        }
        .thumb-block{
            left: 26%;
            top: -7%;
        }
    }
    @media only screen and (max-width: 500px) {
        .thumb-block{
            left: 27%;
        }
    }
    @media only screen and (max-width: 410px) {
        .pricing-block{
            width: 91%;
            margin: 0 35px 40px 15px;
            padding: 40px 15px 35px 15px;
        }
        .thumb-block{
            left: 25%;
        }
    }
    
    
`

export default PricingSectionWrapper;