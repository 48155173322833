import styled from 'styled-components';
import Icon1 from "common/src/assets/images/hosting/features/01.svg";

const FeturesSectionWrapper = styled.div`
    padding: 150px 0 0 0;
    position: relative;
    .col-item{
        padding-left: 12px;
        padding-right: 12px;
    }
    .Row__RowWrapper-bOrpiT{
        padding-left: 3px;
    padding-right: 3px;
    }
   .section-title{
       h2{           
           font-family: 'Hammersmith One';
           font-weight: 400;
           font-size: 43.0537px;
           line-height: 54px;
           letter-spacing: -0.01em;
           color: #003033;
           margin: 0 0 33px 0;
       }
   }
    .fetures-block{
        height: 244px;
        background: #ECF8F9;
        border-radius: 32px;

        padding: 15px;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
        margin-bottom: 24px;
        

        &:hover{
            background: #D9F1F2;
            transition: all 0.2s ease-in;
            border-radius: 16px;
            
            h3{
                color: #01919A;
                letter-spacing: -0.01em; 
                font-weight: 500;
                transition: all 0.3s ease-in;
            }
            .fetures-icon-block svg{
                transition: all 0.3s ease-in;
                -webkit-transform: rotate(-180deg);
                transform: rotate(-180deg) scale(1.1);
            }
            .fetures-icon-block svg path{
                fill: #A6EBF0;
                transition: 0.2s linear;
            }
            .fetures-icon-above-block svg path{
                fill: #01919A;
                border: 0.2px solid #01919A
            }
            .fetures-icon-above-block svg {
                transition: 0.2s linear;
                transform: scale(1.1);
            }
        }
    }
    h3{
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
    }
    .fetures-icon-above-block svg path{
        fill: #00A2AC;
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
    }
    .fetures-icon-above-block svg {
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
    }
    .fetures-icon-block svg{
        transition: all 0.3s ease-in;
        -webkit-transition: 0.3s ease;
    }
    .fetures-icon-block svg path{
        transition: all 0.2s ease-in;
        -webkit-transition: 0.2s ease;
    }
    .fetures-icon-above-block{
        position: absolute;
        top: 46px;
        right: 59px;
        img{
            fill: #00A2AC;
        }

    }
    .fetures-icon-block{
        -webkit-transition: 0.2s ease;
        transition: all 0.2s ease-in;
        position: absolute;
        right: 40px;
        top: 34px;
        width: 60px;
        height: 56px;     
    }
    .fetures-content-block{
        flex-basis: 68%;
        margin-left: 20px;
        h3{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 24px;
            color: #00A2AC;
            margin-bottom: 28px;
            margin-top: 15px;
            transition: all 0.1s ease-in;
            -webkit-transition: 0.1s ease;
        }
        p{
            width: 295px;
            font-weight: 300;
            font-family: 'Poppins';
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            color: #003033;
        }
    }
    @media only screen and (max-width: 1200px) {
        .fetures-content-block{
            margin-left: 0;
            max-width: 100%;
            p{
                width: 100%;
            }
        }
    }
    @media only screen and (max-width: 1180px) {
        // .fetures-icon-above-block{
        //     dislpay: none;
        // }
        // .fetures-icon-block{
        //     dislpay: none;
        // }   
        .fetures-block{
            display: block;
        }
        .fetures-icon-block{
            margin-bottom: 30px;
            top: 20px;
        }
        .fetures-icon-above-block{
            top: 33px;
        }
        .fetures-content-block{
            margin-left: 0;
        }
        .fetures-content-block{
            p{
                display: contents;
            }
            h3{
                font-size: 23px;
            }
        }
    }
    @media only screen and (max-width: 912px) {
        padding: 80px 0 0px 0;
        .fetures-content-block p{
            width: auto;
        }
        .fetures-content-block h3{
            text-align: center;
        }
        .fetures-content-block{
            padding-top: 10px;
        }
        .fetures-icon-block{
            display: none;
            top: -5px;
            left: 47%;
        }
        .fetures-block{
            margin-top:20px;
            height: 90%;
        }
        .fetures-icon-above-block{
            top: 7px;
            right: 42%;
            display: none;
        }
        .col-item{
            margin-top: 15px;
        }
    }
    @media only screen and (max-width: 774px) {
        padding: 80px 0 50px 0;
        .fetures-icon-above-block{
            top: 7px;
            right: 41.5%;
        }
    }
    @media only screen and (max-width: 360px) {
        .fetures-icon-above-block{
            top: -2px!important;
            right: 45%!important;
        }
    }  
    @media only screen and (max-width: 770px) {
        .fetures-icon-above-block{
            right: 42%;
        }
    }
    @media only screen and (max-width: 700px) {
        .fetures-icon-above-block{
            right: 47%;
        }
    }
    @media only screen and (max-width: 578px) {
        .fetures-content-block p{
            width: auto;
        }
        .fetures-icon-block{
            top: -15px;
            left: 44%;
        }
        .fetures-block{
            margin-top: 10px;
        }
        .fetures-content-block{
          text-align: center;
          padding-top: 15px;
        }
        .fetures-icon-above-block{
            top: -2px;
            right: 48%;
        }
    }
    @media only screen and (max-width: 480px) {
        .fetures-icon-above-block{
            right: 45.2%;
        }
    }
    @media only screen and (max-width: 500px) {
        .fetures-icon-above-block{
            right: 46.2%;
        }
    }
    
`

export default FeturesSectionWrapper;