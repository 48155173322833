const Data = {
  menuItems: [
    {
      name: "Home",
      path: "/#main"
    },
    {
      name: "Use",
      path: "/#use",
      offset: "-50"
    },
    {
      name: "Chatbot",
      path: "/#chatbot",
      offset: "-50"
    },
    {
      name: "Pricing",
      path: "/#pricing",
      offset: "-50"
    },
    // {
    //   name: "Comments",
    //   path: "/#comments",
    //   offset: "-50"
    // },
    {
      name: "Faq",
      path: "/#faq",
      offset: "-50"
    },
    // {
    //   name: "Blog",
    //   path: "/blog-grid",
    //   subItems: [
    //     {
    //       name: "Blog Grid",
    //       path: "/blog-grid"
    //     },
    //     {
    //       name: "Blog List",
    //       path: "/blog-list"
    //     },
    //     {
    //       name: "Blog Single",
    //       path: "/blog-single"
    //     }
    //   ]
    // },
    {
      name: "Contact",
      path: "/#contact",
      offset: "-50"
    }
  ]
};
export default Data;
