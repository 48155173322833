import image1 from 'common/src/assets/images/app/testimonial/testimonial-thumb-1.jpg';
import image2 from 'common/src/assets/images/app/testimonial/testimonial-thumb-2.jpg';
import image3 from 'common/src/assets/images/app/testimonial/testimonial-thumb-3.jpg';
import image4 from 'common/src/assets/images/app/testimonial/testimonial-thumb-4.jpg';
import image5 from 'common/src/assets/images/app/testimonial/testimonial-thumb-5.jpg';
import image6 from 'common/src/assets/images/app/testimonial/testimonial-thumb-6.jpg';

const data = {
  thumbs: [image1, image2, image3, image4, image5, image6],
  testimonials: [
    {
        quote:
          'Despite the laws of Physics Dialong allows you to get closer to your clients.',
        author: 'Albert Einstein',
        dsignation: 'Theoretical physicist',
        thumb: image1
    },
    {
        quote:
          "Hey guys! Your project is awesome! I'd like to work with you.",
        author: 'Bill Gates',
        dsignation: 'American business magnate',
        thumb: image2
    },
    {
        quote:
          "Don't you mind to place my head on the Dialong logo?",
        author: 'Benjamin Franklin',
        dsignation: 'Scientist and inventor from USA',
        thumb: image3
    },
    {
        quote:
          "The Greatest App I've ever seen! I wish I intalled it on my lovely spaceship to be in contact with Baikonur.",
        author: 'Yuri Gagarin',
        dsignation: 'Soviet cosmonaut',
        thumb: image4
    },
    {
        quote:
          "Dialong is fast... Even faster than my top car.",
        author: 'Karl Benz',
        dsignation: 'German engine designer and automotive engineer',
        thumb: image5
    },
    {
        quote:
          "Thanks for such a great application. I appriciate your approach very musch. It's so cool and creative. I'm gonna write a song about it.",
        author: 'Michael Jackson',
        dsignation: 'American singer-songwriter',
        thumb: image6
    }
  ]
};
export default data;
