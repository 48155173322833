import styled from 'styled-components';

const FaqSectionWrapper = styled.div`
  padding: 0px 0 0px 0;
  position: relative;
  overflow: initial;
  .section__particle {
    position: absolute;
    &.one {
      width: 50px;
      left: 8%;
      top: 14%;
    }
    &.two {
      width: 180px;
      top: 43%;
      right: -55px;
    }
  }
  .faq-fone{
    margin-left: -52%;
  }
  .faq-img{
    position: absolute;
    left: -60px;
    top: 65px;
  }
  .line-img{
    position: absolute;
    max-width: none;
    position: absolute;
    left: -33%;
    top: -60%;
    z-index: 1;
  }
  .row-block{
    flex-direction:row-reverse;
  }
  .item-answer-name{
    font-style: normal;
    font-weight: 500!important;
    font-size: 14px;
    line-height: 14px;
    color: #008891;
  }
  .item1{
    margin-top: 4%;
  }
  .item3{
    margin-top: 2%;
  }
  .svg-fone-icon{
    position: absolute;
    z-index: 1;
    right: 0;
    top: -16px;
  }
  .done-icon{
    z-index: 10;
    right: 9px;
    top: 6px;
  }
  .close-icon{
    z-index: 10;
    right: 9px;
    top: 6px;
  }
  .accordion-item-answer-item{
    padding: 25px 32px 32px 32px;
    height: auto;
    position: relative;
    margin-left: 30%;
    width: 414px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%);
    border-radius: 32px 32px 2px 32px;
    margin-top: 16px;

    span{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-align: right;
      color: #41897C;
      position: absolute;
      right: 40px;
      bottom: 12px;
    }
    svg{
      position: absolute;
      right: 16px;
      bottom: 10px;
    }
  }

  p{
    margin-top: 8px!important ;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #003033;
  }
  
  .accordion-item-ask{
    padding: 32px 29px 32px 32px;
    background: #ECF8F9!important;
    border-radius: 2px 32px 32px 32px;
    width: 540px;
    height: auto;

    p{
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #003033;
    }
  }
  .accordion__item + .accordion__item {
    border-color: transparent;
  }
  .reusecore__accordion {
    margin-bottom: 80px;
  }
  .accordion__item {
    .accordion__header {
      h5 {
        line-height: 24px;
        font-weight: 300;
        font-size: 16px;
        color: ${props => props.theme.black};
      }
      > div {
        &:focus {
          outline: none;
        }
      }
    }

    .accordion__body {
      p {
        font-size: 16px;
        font-weight: 300;
        margin: 0;
      }
    }
  }
  .accordion__item + .accordion__item {
    margin-top: 16px;
  }
  .faq-thumb {
    text-align: center;
    transform: translateX(80px);
  }
  .section-title {
    h2 {
      margin-bottom: 33px;
      span{
        font-family: 'Hammersmith One';
        font-style: normal;
        font-weight: 400;
        font-size: 43.0537px;
        line-height: 54px;
        letter-spacing: -0.01em;
        color: #003033;
      }
    }
  }
  .faq-thumb-block {
    order: 2;
    text-align: -webkit-center;
    padding-left: 2.5%;
  }
  .faq-content-block {
    order: 1;
  }
  @media only screen and (max-width: 1400px) {
    .line-img{
      display: none;
    }

    .faq-thumb {
      transform: translateX(0px);
      width: 75%;  
    }
    .section-title {
      text-align: center;
    }
    .section__particle {
      &.one {
        left: 2%;
      }
    }
  }
  @media only screen and (max-width: 1180px) {
    .line-img{
      display: none;
    }
    .faq-img{
      left: 26%;
      top: 78px;
      width: 50%;
    }
    .faq-thumb-block{
      margin-bottom: 15px;
      max-width: 100%;
      text-align: -webkit-center;
    }
    .faq-fone{
      margin-left: 0;
      width: 55%;
    }
    .row-block{
      flex-direction: column-reverse;
    }
    .section__particle {
      &.one {
        left: 3%;
        top: 5%;
      }
      &.two {
        top: auto;
        bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 912px) {
    .line-img{
      display: none;
    }
    .faq-thumb {
      transform: translateX(0px);
      width: 40%;  
    }
    .row-block{
      flex-direction: column-reverse;
    }
    .faq-fone{
      margin-left: 0%!important;
      width: 90%;
    }
    .faq-img{
      width: 90%;
      left: 7%;
      top: 15%;
    }
    .faq-thumb-block{
      margin-bottom: 20px!important;

    }
    .accordion-item-ask{
      width: auto;
      height: auto;
    }
    .accordion-item-answer-item{
      width: auto;
      margin-left: 10%;
    }
    .reusecore__accordion {
      margin-bottom: 50px;
      text-align: -webkit-center!important;
    }
  }

  @media only screen and (max-width: 568px) {
    .section-title {
      text-align: center;
    }
    .row-block{
      flex-direction: column;
    }
    .reusecore__accordion {
      margin-bottom: 50px;
      text-align: -webkit-center;
    }
    .faq-thumb-block {
      order: 1;
      text-align: -webkit-center;
      margin-bottom: 60px;
    }
    .faq-content-block {
      order: 2;
    }
    .faq-fone{
      width: 90%;
    }
  }
  @media only screen and (max-width: 480px) {
    .reusecore__accordion {
      margin-bottom: 50px;
      text-align: -webkit-center;
    }
    .row-block{
      flex-direction: column;
    }
    .faq-fone{
      width: 90%;
    }
    .accordion__item {
      .accordion__header {
        h5 {
          font-size: 13px;
          line-height: 21px;
        }
      }
    }
  }
`;

export default FaqSectionWrapper;
