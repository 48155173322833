
const data = {
    faqs: [
      {
        title: "Dialong doesn't support a communication channel which is extremely needed. What can you do?",
        content: "We'll be happy to implement it for you on request!",
        class: "item1",
        blockClass: "accordion-item-answer-item"
      },
      {
        title: 'What if my message limit is over? Will you disconnect me?',
        content: "No, sure no! You will be notified when your limit is about to be over. And you will be able to buy in. Even in case if the limit is complitely over we won't instantly disconnect you. We will contact you and ask for renewal.",
        class: "item2",
        blockClass: "accordion-item-answer-item"
      },
      {
        title: 'How many operators are able to work with Dialong at the same time?',
        content: 'Unlimited. You just need a Creatio User License for each operator.',
        class: "item3",
        blockClass: "accordion-item-answer-item"
      }
    ]
  };
  export default data;
  