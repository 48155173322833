import styled from 'styled-components';

const AboutSectionWrapper = styled.div`
    padding: 90px 0 44px 0;
    z-index: 10;
    position: relative;
    text-align: center;
    .section__particle{
        position: absolute;
        &.one{
            right: 9%;
            top: 19%;
            height: 34px;
            width: 40px;
            transform: rotate(-45deg);
        }
        &.two{
            width: 150px;
            top: auto;
            bottom: 80px;
            left: -22px;
        }
    }
    .images-block{
        position: relative;
        .about-thumb-two{
            position: absolute;
            left: 50px;
            top: 18%;
        }
    }

    .about-content-wrap{
        text-align: left;
    }
   
    .about-thumb{
        margin-left: 35px;
        transform: translateX(-80px);
        display: inline-block;
    }
    .about-text.text-one{
        width: 100%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #003033;
        opacity: 0.7;
        text-align: left;
    }
    
    .about-text.text-two{
        margin-bottom: 44px;
        margin-top: 20px;
        width: 100%;
        height: 60px;
        text-align: left;
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #00A2AC;
    }
    .section-title{
        h2{
            margin-bottom: 18px;
 
            span {
                font-family: 'Hammersmith One';
                font-style: normal;
                font-weight: 400;
                font-size: 44px;
                line-height: 56px;
                letter-spacing: -0.01em;
                color: #003033;
                width: 468px;
            }
        }
    }
    .src-btn {
        text-align: center;
        width: 220px;
        height: 48px;
        background: #00C0CC;
        border-radius: 53px;
        padding-top: 10px;
        transition-property: background;

        a{
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;        
            font-family: 'Poppins';
            font-style: normal;
        }

        &:hover{
            background: linear-gradient(130.58deg, #9EFBD3 27.98%, #57E9F2 75.83%, #45D4FB 127.66%), #00C0CC;

            a{
                color: #00646A;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .section__particle{
            &.one{
                top: 9%;
            }
            &.two{
                bottom: -92px;
                left: -38px;
            }
        }
        .about-thumb-two{
            left: 17%;
            top: 23%;
        }
     }
     
     @media only screen and (max-width: 1190px) {
        .about-thumb-two{
            left: 1%!important;
            top: 23%!important;
        }
        .src-btn{
            margin-top:20px;
        }
     }
     @media only screen and (max-width: 1024px) {
        .about-thumb-two{
            max-width: 78%;
            left: 17%;
            top: 23%;
            margin-left: 10%;
        }
        .about-thumb{
            margin-left: 18%;
        }
        .about-text{
            width: 100%!important;
        }
        .section__particle{
            &.one {
                top: 0%;
            }
        }
        .text-two{
            margin-bottom: 80px!important;
        }
    }
    @media only screen and (max-width: 912px) {
        #about{
            height: 950px;
        }
        .src-btn{
            margin-top: 75px;
        }
        padding: 100px 0 60px 0;
        .about-text{
            width: 100%!important;
        }
        .section-title h2{
            margin-bottom: 20px;
        }
        .about-text.text-two{
            margin-bottom: 30px;
        }
        .about-thumb{
            margin-left: 22%;
        }
        .about-thumb-two{
            margin-left: 10%;
        }
    }
    @media only screen and (max-width: 760px) {
        padding: 60px 0 60px 0;
        .about-text{
            width: 100%!important;
        }
       .about-thumb{
           width: 85%;
           margin-bottom: 40px;
           margin-left: 33%;
       }
       .about-thumb-two{
            margin-left: 13%;
            margin-top: -5%;
       }
    }
    @media only screen and (max-width: 568px) {
         .section-title,
        .about-content-wrap{
            text-align: -webkit-center;
        }
        .images-block{
            position: absolute;
            opacity: 0.2;
            display: none;
        }
        .section-title{
            img{
                display: none;
            }
            h4{
                width: 100%;
            }
        }
        .about-text{
            width: 100%!important;
            text-align: center!important;
        }
        .src-btn{
            margin-top: 70px;
        }
        
     }
`
export default AboutSectionWrapper;