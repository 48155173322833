class TelegramGroup {
    constructor() {
      this.token = "978215053:AAGMKzriNgbAL-v0uiFlp6TUQCNBlhxDK4w";
      this.chatId = "-256159903";
    }

    sendLead(lead) {
        if(!lead) {
            throw "Information is invalid"
        }

        const text = 
`*Сообщение с формы сайта dialong.io:*
*Имя:* ${lead.name}
*Телефон:* ${lead.phone}
*Email:* ${lead.email}
*Сообщение:*
----
${lead.message}
----`;
        
        const url = `https://api.telegram.org/bot${this.token}/sendMessage`;

        const json = JSON.stringify({
            chat_id : this.chatId,
            text : text,
            parse_mode : "Markdown"
        });

        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: json
        });
    }
}

export default TelegramGroup;